.at_assoc {
  .global-header {
    background: #10485d;
    border-bottom: 36px solid #fff;

    .logo {
      content: url("https://stijl.kuleuven.be/associatie/images/logo_asso_stripe.png");
      width: 160px;
      height: 68px;
      top: 7px;
    }
  }

  .flyout {
    > a {
      display: none;
    }
    #portal-globalnav {
      width: 100%;
    }
  }

  .local-footer .footer-navbar {
    background-color: #10485d;
  }

  /* footer logos */
  footer.global-footer .bottom-footer .assopartners {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 2em;

    a {
      opacity: 0.6;
      transition: opacity ease-in-out 0.125s;

      &:hover {
        opacity: 1;
        transition: opacity ease-in-out 0.125s;
      }
    }
  }

  /* accordion - panel-title */
  div[id^="accordion"] {
    .panel {
      margin-bottom: 1em;
      border-bottom: 1px dashed #1d8db0;
      padding-bottom: 1em;
      padding-left: 1em;
    }

    .panel-collapse {
      margin-bottom: 0.5em;
    }

    h4.panel-title {
      font-weight: 400;

      a {
        &::before {
          content: "clear";
          padding-right: 0.5em;
          padding-bottom: 0.25em;
          color: #666;
          margin-left: -1.5em;
          direction: ltr;
          display: inline-block;
          font-family: "Material Icons";
          font-style: normal;
          font-weight: normal;
          letter-spacing: normal;
          text-transform: none;
          white-space: nowrap;
          word-wrap: normal;
          font-feature-settings: "liga";
          -webkit-font-smoothing: antialiased;
          vertical-align: middle;

          &:hover {
            color: #1d8db0;
          }
        }

        &.collapsed::before {
          content: "add";
        }
      }
    }

    h6 {
      margin-top: 0.5em;
      margin-bottom: 0.75em;
    }
  }

  .card-img-bg-centre,
  .card-img-bg-centre-50 {
    background-size: 80%;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .card-img-bg-centre-50 {
    background-size: 50%;
    min-height: 5rem;
  }
}
